import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Rating from "../common/Rating/Rating";
import DeletePost from "../post/DeletePost";

class ReasonsMarkup extends React.Component {
    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
    }

    handler = (e) => {
        // console.log(e.currentTarget.attributes);
        const index = e.currentTarget.attributes.getNamedItem("data-index")
            .value;
        this.props.editReasons(index);
    };

    render() {
        const { filteredPosts } = this.props;
        if (filteredPosts == null || filteredPosts.length <= 0) {
            return null;
        }

        return (
            <div>
                {filteredPosts.map((post, i) => {
                    return (
                        <div>
                            {post.body.map((reason, index) => (
                                <div
                                    className={"reason-expanded"}
                                    key={`postId-${index}`}
                                >
                                    <h5>
                                        {index + 1}. {reason}
                                    </h5>
                                </div>
                            ))}
                            {/* <div className="subtitle">
                                <h5>Rating: </h5>
                                <Rating value={3} readOnly />
                            </div> */}

                            <div
                                style={{
                                    position: "relative",
                                    overflow: "visible",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "100%",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    key={`edit-button-${i}`}
                                    data-index={i}
                                    color="primary"
                                    className="edit-button button"
                                    onClick={this.handler}
                                    style={{
                                        paddingTop: "none",
                                        marginTop: "none",
                                    }}
                                >
                                    Edit
                                </Button>
                                <DeletePost postId={post.postId} />
                            </div>
                            <br />
                            <br />
                            <br />
                            {/* <hr
                                style={{
                                    width: "100%",
                                    height: "10px",
                                    border: "none",
                                    backgroundColor: "#0fadef",
                                }}
                            /> */}
                        </div>
                    );
                })}
            </div>
        );
    }
}

ReasonsMarkup.propTypes = {
    filteredPosts: PropTypes.array.isRequired,
    editReasons: PropTypes.func.isRequired,
};

export default ReasonsMarkup;
