import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import flag from "../assets/flag.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../components/auth/CheckoutForm";
import Grid from "@material-ui/core/Grid";

import ContributeButton from "../util/ContributeButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    multilineColor: {
        color: "white",
    },
});

const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF",
    },
})(Typography);

class contribute extends Component {
    constructor() {
        super();
        this.state = {
            selectedOption: 1,
            values: [7.07, 17.07, 27.07],
            other: "",
        };
        this.handleClick.bind(this);
        this.handleChange.bind(this);
        this.componentWillReceiveProps.bind(this);
    }

    // onValueChange(event) {
    //     this.setState({
    //         selectedOption: event.target.value,
    //     });
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleClick = (number) => {
        this.setState({
            selectedOption: number,
        });
    };

    render() {
        // const { errors } = this.state;
        const {
            classes,
            UI: { loading },
        } = this.props;
        return (
            <div className="contribute-page">
                <img
                    src={flag}
                    alt="United states flag"
                    className="contribute-page-banner"
                />
                <h1 className="page-title">Contribute</h1>
                <div className="contribute-page-content">
                    <h5>
                        Top7Reasons is a grass-roots effort, run by three
                        ordinary Americans concerned about the political
                        discourse in this country. So we’ve created this forum
                        for rational voters to post rational reasons why the
                        other side should see issues in a new light…and for
                        rational voters to see what other people just like them
                        are thinking.
                    </h5>
                </div>
                <br />
                <div className="contribute-page-content">
                    <h5>
                        We’re not in this for the publicity. But, building and
                        maintaining this movement has required a lot of time and
                        money for the programming, tech, editing, and more. We
                        are not a not-for-profit, but your support will help us
                        improve Top7Reasons so that we can all keep the rational
                        conversation thriving. Thank you.
                    </h5>
                </div>

                <Grid
                    container
                    spacing={2}
                    id="contribution-buttons"
                    className="grid-column"
                    justify="center"
                    style={{ marginTop: "50px" }}
                >
                    <div className="button-container">
                        <div className="button-container">
                            <Button
                                style={{
                                    backgroundColor:
                                        this.state.selectedOption === 1
                                            ? "#0f2444"
                                            : "#2650d4",
                                    border: "2px solid #2650d4",
                                }}
                                className="button left"
                                onClick={() => this.handleClick(1)}
                            >
                                ${this.state.values[0]}
                            </Button>
                            <Button
                                style={{
                                    backgroundColor:
                                        this.state.selectedOption === 2
                                            ? "#0f2444"
                                            : "#2650d4",
                                    border: "2px solid #2650d4",
                                }}
                                className="button left"
                                onClick={() => this.handleClick(2)}
                            >
                                ${this.state.values[1]}
                            </Button>
                            <Button
                                style={{
                                    backgroundColor:
                                        this.state.selectedOption === 3
                                            ? "#0f2444"
                                            : "#2650d4",
                                    border: "2px solid #2650d4",
                                }}
                                className="button left"
                                onClick={() => this.handleClick(3)}
                            >
                                ${this.state.values[2]}
                            </Button>
                            <Button
                                style={{
                                    backgroundColor:
                                        this.state.selectedOption === 4
                                            ? "#0f2444"
                                            : "#2650d4",
                                    border: "2px solid #2650d4",
                                }}
                                className="button left"
                                onClick={() => this.handleClick(4)}
                            >
                                Other
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid container spacing={2} justify="center">
                    <div className="button-container">
                        {this.state.selectedOption !== 4 ? null : (
                            <TextField
                                id="other"
                                name="other"
                                type="text"
                                label="Enter your contribution amount here:"
                                className="text-field"
                                value={this.state.other}
                                onChange={this.handleChange}
                                InputProps={{
                                    className: classes.multilineColor,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            style={{ color: "white" }}
                                        >
                                            <WhiteTextTypography>
                                                $
                                            </WhiteTextTypography>
                                        </InputAdornment>
                                    ),
                                }}
                                error={
                                    this.state.other === "" ||
                                    parseFloat(this.state.other) < 5
                                }
                                helperText={
                                    this.state.other === ""
                                        ? "Empty field!"
                                        : parseFloat(this.state.other) < 0
                                        ? "Nice try."
                                        : parseFloat(this.state.other) < 5
                                        ? "Minimum contribution is $5"
                                        : " "
                                }
                            />
                        )}
                    </div>
                </Grid>
                <Grid
                    container
                    id="checkout-container"
                    spacing={2}
                    justify="center"
                >
                    <div id="checkout-elements" className="AppWrapper">
                        <Elements
                            stripe={stripePromise}
                            options={ELEMENTS_OPTIONS}
                        >
                            <CheckoutForm
                                price={
                                    this.state.selectedOption != 4
                                        ? this.state.values[
                                              this.state.selectedOption - 1
                                          ]
                                        : this.state.other !== "" &&
                                          parseFloat(this.state.other) >= 0
                                        ? parseFloat(this.state.other)
                                        : 0
                                }
                                onSuccessfulCheckout={() => {
                                    document.getElementById(
                                        "checkout-elements"
                                    ).style.display = "none";
                                    document.getElementById(
                                        "contribution-buttons"
                                    ).style.display = "none";
                                    document.getElementById(
                                        "checkout-thanks"
                                    ).style.height = "470px";
                                    document.getElementById(
                                        "checkout-thanks"
                                    ).style.marginTop = "250px";
                                    document.getElementById(
                                        "checkout-thanks"
                                    ).style.display = "flex";
                                }}
                            />
                        </Elements>
                    </div>
                    <div id="checkout-thanks" style={{ display: "none" }}>
                        <div className="contribute-page">
                            <h1 className="page-title">
                                Thank you for your generosity
                            </h1>
                        </div>
                    </div>
                </Grid>
            </div>
        );
    }
}

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
        },
    ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    "pk_live_51HcK8GJAGXDAFijjbCU7I2dnujy51DYkLLL5WUmdUdUD0CnM32WVywdZevK1t4PIb0FfUJsesEt9Pr01yLsPdnjC00cy1H9IAX"
);

contribute.propTypes = {
    UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    UI: state.UI,
});

export default connect(mapStateToProps, {})(withStyles(styles)(contribute));
