import styled from "@emotion/styled";

const Row = styled.div`
    width: 500px;
    margin: 30px auto;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
        inset 1px 2px 1px white;
    border-radius: 4px;
    background-color: #2650d4;
    position: relative;
`;

export default Row;
