import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

export default ({ children, onClick, tip, btnClassName, tipClassName }) => (
    <Tooltip
        disableFocusListener
        title={tip}
        className={tipClassName}
        placement="bottom"
    >
        <IconButton aria-label={tip} onClick={onClick} className={btnClassName}>
            {children}
        </IconButton>
    </Tooltip>
);
