import React from "react";
import { red } from "../../styles/constants.scss";

const ChecklistIcon = ({ color = red }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 251 251"
        height={150}
        width={150}
    >
        {/* <title>Asset 8</title> */}
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <rect
                    class="cls-1"
                    x="0.5"
                    y="0.5"
                    width="250"
                    height="250"
                    rx="125"
                    fill={color}
                    stroke="#fff"
                    stroke-miterlimit="10"
                    opacity="0.95"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M174,180.41l1.13-112.65a3.39,3.39,0,0,0-1-2.39,3.26,3.26,0,0,0-2.35-1l-31.39-.21a16.66,16.66,0,0,0-16.33-13.77,16.65,16.65,0,0,0-11.95,4.91,17.06,17.06,0,0,0-4.68,8.64l-31.37-.21a3.34,3.34,0,0,0-2.37,1,3.39,3.39,0,0,0-1,2.37L71.58,179.73a3.35,3.35,0,0,0,2.85,3.37,3.15,3.15,0,0,0,.44,0l95.73.63a3.37,3.37,0,0,0,2.36-1A3.43,3.43,0,0,0,174,180.41ZM116.78,60.1a10,10,0,0,1,7.22-3,8.67,8.67,0,0,1,1.35.1,10.3,10.3,0,0,1-1.56,20.48,10.1,10.1,0,0,1-1.35-.1,10.31,10.31,0,0,1-5.66-17.51ZM78.27,176.41,79.33,70.5l28.06.18a16.77,16.77,0,0,0,33,.22l28.06.18L167.34,177Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M93.39,106.9a3.17,3.17,0,0,0,.28.34,1.29,1.29,0,0,0,.19.21,3.61,3.61,0,0,0,.3.25l.26.18s0,0,.07.05a3.09,3.09,0,0,0,.32.14,1.89,1.89,0,0,0,.21.09,2.88,2.88,0,0,0,.64.17,3.25,3.25,0,0,0,.54,0h.17l.36,0,.21,0,.32-.11.2-.07a3.9,3.9,0,0,0,.35-.19l.1,0,0,0a2.86,2.86,0,0,0,.48-.38l.09-.09a3.51,3.51,0,0,0,.29-.36l.13-.18.1-.15,11.56-20.76a3.39,3.39,0,0,0-1.26-4.59,3.32,3.32,0,0,0-4.54,1.29L96,98.43l-2.24-3.57a3.3,3.3,0,0,0-4.59-1,3.4,3.4,0,0,0-1,4.66l5.21,8.31A.86.86,0,0,0,93.39,106.9Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M111.92,104.29a3.15,3.15,0,0,0,.44,0l37.08.25a3.37,3.37,0,0,0,3.36-3.35,3.33,3.33,0,0,0-3.29-3.39l-37.08-.25a3.38,3.38,0,0,0-.51,6.71Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M93.13,132.91c.09.12.18.23.28.34l.19.21a3.5,3.5,0,0,0,.3.24,2,2,0,0,0,.26.18l.07.06.32.14a1.33,1.33,0,0,0,.21.09,2.86,2.86,0,0,0,.64.16,2.42,2.42,0,0,0,.54,0h.17a2.23,2.23,0,0,0,.36,0l.21-.05a2,2,0,0,0,.32-.1l.2-.08a2.21,2.21,0,0,0,.34-.18l.11,0,0,0a3.48,3.48,0,0,0,.48-.38l.09-.1a3.37,3.37,0,0,0,.29-.35,1.27,1.27,0,0,0,.13-.19s.07-.09.1-.15l11.56-20.76a3.39,3.39,0,0,0-1.27-4.59,3.32,3.32,0,0,0-4.53,1.3L95.7,124.44l-2.24-3.58a3.3,3.3,0,0,0-4.59-1,3.4,3.4,0,0,0-1,4.65l5.21,8.32A.7.7,0,0,1,93.13,132.91Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M149.18,130.58a3.37,3.37,0,0,0,3.36-3.35,3.33,3.33,0,0,0-3.29-3.39l-37.08-.25a3.38,3.38,0,0,0-.51,6.71,3.15,3.15,0,0,0,.44,0Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M108.79,134.1a3.3,3.3,0,0,0-4.53,1.29l-8.83,15.85-2.24-3.57a3.3,3.3,0,0,0-4.59-1,3.4,3.4,0,0,0-1,4.65l5.22,8.32a.39.39,0,0,0,.08.1,2.24,2.24,0,0,0,.27.34,1.9,1.9,0,0,0,.19.21,1.83,1.83,0,0,0,.31.24l.25.19a.18.18,0,0,1,.07.05c.11.06.21.09.32.14l.21.09a2.86,2.86,0,0,0,.64.16,2.45,2.45,0,0,0,.54,0h.17a2.23,2.23,0,0,0,.36-.05l.21,0a1.7,1.7,0,0,0,.32-.1l.2-.07a3.9,3.9,0,0,0,.35-.19l.1-.05,0,0a3.48,3.48,0,0,0,.48-.38l.09-.09a2.6,2.6,0,0,0,.3-.36l.12-.18c0-.06.07-.1.1-.16l11.56-20.76A3.38,3.38,0,0,0,108.79,134.1Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M149,150.64l-37.08-.25a3.38,3.38,0,0,0-.51,6.71,3.25,3.25,0,0,0,.44,0l37.08.25a3.37,3.37,0,0,0,3.36-3.35A3.33,3.33,0,0,0,149,150.64Z"
                />
            </g>
        </g>
    </svg>
);

export default ChecklistIcon;
