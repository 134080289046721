import React from "react";
import { string } from "prop-types";

import BackgroundOpacity from "../../common/BackgroundOpacity/BackgroundOpacity";

import "./image-banner.scss";
import Banner from "../../../assets/trump.jpg";

const ImageBanner = () => (
    <div className="image-banner">
        <BackgroundOpacity background={Banner} opacity={0.2} />
        <div className="image-overlay">
            <div className="main-quote" style={{ paddingTop: "25px" }}>
                <span>
                    <p
                        style={{
                            padding: "none",
                            margin: "0 0",
                            position: "relative",
                            top: "-8px",
                            fontSize: "1em",
                        }}
                    >
                        Does Donald Trump
                    </p>
                </span>
                <span>
                    <p
                        style={{
                            padding: "none",
                            margin: "0 0",
                            position: "relative",
                            top: "-8px",
                            fontSize: "1em",
                        }}
                    >
                        deserve 4 more years?
                    </p>
                </span>
            </div>
            <div className="secondary-labels">
                <span className="large-desktop-only">
                    Here’s your chance to make an impact. <br /> Give America
                    your Top7 Reasons <br /> why he does or doesn’t!
                </span>
                <span className="large-desktop-only">
                    Are you Undecided on this? <br />
                    Read what Americans like you are thinking!
                </span>
            </div>
        </div>
    </div>
);

ImageBanner.propTypes = {
    image: string,
    mainQuote: string,
    leftLabel: string,
    rightLabel: string,
};

export default ImageBanner;
