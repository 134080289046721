import React from "react";
import ReactDOM from "react-dom";

import "./styles/2-Card-Detailed.css";
import { logoBlue, logoRed, blue, red } from "../../styles/constants.scss";

import { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import axios from "axios";

import Row from "./prebuilt/Row";
import BillingDetailsFields from "./prebuilt/BillingDetailsFields";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";

const CardElementContainer = styled.div`
    height: 40px;
    display: flex;
    align-items: center;

    & .StripeElement {
        width: 100%;
        padding: 15px;
    }
`;

const CheckoutForm = ({ price, onSuccessfulCheckout }) => {
    const [isProcessing, setProcessingTo] = useState(false);
    const [checkoutError, setCheckoutError] = useState();

    const stripe = useStripe();
    const elements = useElements();

    // TIP
    // use the cardElements onChange prop to add a handler
    // for setting any errors:

    const handleCardDetailsChange = (ev) => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    const handleFormSubmit = async (ev) => {
        ev.preventDefault();

        const billingDetails = {
            name: ev.target.name.value,
            email: ev.target.email.value,
            // address: {
            //     city: ev.target.city.value,
            //     line1: ev.target.address.value,
            //     state: ev.target.state.value,
            //     postal_code: ev.target.zip.value,
            // },
        };

        setProcessingTo(true);

        const cardElement = elements.getElement("card");

        try {
            // TODO: IMPLEMENT PAYMENT IN BACK END
            if (price * 100 < 500) {
                setCheckoutError("Minimum contribution amount is $5");
                setProcessingTo(false);
                return;
            }
            const { data: clientSecret } = await axios.post("/payments", {
                amount: price * 100,
                currency: "usd",
                name: billingDetails.name,
                email: billingDetails.email,
            });

            console.log(clientSecret);
            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: billingDetails,
            });

            console.log(paymentMethodReq);

            if (paymentMethodReq.error) {
                setCheckoutError(paymentMethodReq.error.message);
                setProcessingTo(false);
                return;
            }

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodReq.paymentMethod.id,
            });

            if (error) {
                setCheckoutError(error.message);
                setProcessingTo(false);
                return;
            }

            setProcessingTo(false);
            onSuccessfulCheckout();
        } catch (err) {
            setCheckoutError(err.message);
        }
    };

    // IMPORTANT:
    // CardElement itself is housed in iframe and so styles need to be passed in
    const iframeStyles = {
        base: {
            color: "#fff",
            fontSize: "16px",
            iconColor: "#fff",
            "::placeholder": {
                color: "#4065dd",
            },
        },
        invalid: {
            iconColor: "#f1c655",
            color: "#f1c655",
        },
        complete: {
            iconColor: "#cbf4c9",
        },
    };

    const cardElementOpts = {
        iconStyle: "solid",
        style: iframeStyles,
        hidePostalCode: false,
    };

    return (
        <form
            onSubmit={handleFormSubmit}
            style={{
                paddingTop: "20px",
                float: "left",
            }}
            className="beepboop"
        >
            <Row className="beepboop">
                <BillingDetailsFields />
            </Row>
            <Row className="beepboop">
                <CardElementContainer>
                    <CardElement
                        options={cardElementOpts}
                        onChange={handleCardDetailsChange}
                    />
                </CardElementContainer>
            </Row>
            {checkoutError && (
                <CheckoutError style={{ justifyContent: "center" }}>
                    {checkoutError}
                </CheckoutError>
            )}
            <Row className="beepboop">
                {/* TIP always disable your submit button while processing payments */}
                <SubmitButton disabled={isProcessing || !stripe}>
                    {isProcessing ? "Processing..." : `Pay $${price}`}
                </SubmitButton>
            </Row>
        </form>
    );
};

export default CheckoutForm;
