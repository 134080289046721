import axios from "axios";
import {
    SET_POSTS,
    LOADING_DATA,
    LIKE_POST,
    UNLIKE_POST,
    DELETE_POST,
    SET_ERRORS,
    POST_POST,
    CLEAR_ERRORS,
    LOADING_UI,
    SET_POST,
    STOP_LOADING_UI,
    SUBMIT_COMMENT,
    SET_WORDS,
    SET_FILTERED_POSTS,
    SET_REGIONS,
} from "../types";
import { applyQueryParams } from "../../util/helper";

// Get all posts
export const getPosts = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get("/posts")
        .then((res) => {
            dispatch({
                type: SET_POSTS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_POSTS,
                payload: [],
            });
        });
};
export const getPost = (postId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
        .get(`/post/${postId}`)
        .then((res) => {
            dispatch({
                type: SET_POST,
                payload: res.data,
            });
            dispatch({ type: STOP_LOADING_UI });
        })
        .catch((err) => console.log(err));
};
// Post a post
export const postPost = (newPost) => async (dispatch) => {
    dispatch({ type: LOADING_UI });
    const response = await axios
        .post("/post", newPost)
        .then((res) => {
            dispatch({
                type: POST_POST,
                payload: res.data,
            });
            dispatch(clearErrors());
            return {
                complete: true,
            };
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
            return {
                complete: false,
            };
        });
    return response;
};

// Edit a post
export const editPost = (newPost) => async (dispatch) => {
    dispatch({ type: LOADING_UI });
    const response = await axios
        .post("/editpost", newPost)
        .then((res) => {
            dispatch({
                type: POST_POST,
                payload: res.data,
            });
            dispatch(clearErrors());
            return {
                complete: true,
            };
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
            return {
                complete: false,
            };
        });
    return response;
};

// TODO: update to rating system
// Like a post
export const likePost = (postId) => (dispatch) => {
    axios
        .get(`/post/${postId}/like`)
        .then((res) => {
            dispatch({
                type: LIKE_POST,
                payload: res.data,
            });
        })
        .catch((err) => console.log(err));
};
// Unlike a post
export const unlikePost = (postId) => (dispatch) => {
    axios
        .get(`/post/${postId}/unlike`)
        .then((res) => {
            dispatch({
                type: UNLIKE_POST,
                payload: res.data,
            });
        })
        .catch((err) => console.log(err));
};
// Submit a comment
export const submitComment = (postId, commentData) => (dispatch) => {
    axios
        .post(`/post/${postId}/comment`, commentData)
        .then((res) => {
            dispatch({
                type: SUBMIT_COMMENT,
                payload: res.data,
            });
            dispatch(clearErrors());
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data,
            });
        });
};
export const deletePost = (postId) => (dispatch) => {
    axios
        .delete(`/post/${postId}`)
        .then(() => {
            dispatch({ type: DELETE_POST, payload: postId });
        })
        .catch((err) => console.log(err));
};

export const getUserData = (userHandle) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(`/user/${userHandle}`)
        .then((res) => {
            dispatch({
                type: SET_POSTS,
                payload: res.data.posts,
            });
        })
        .catch(() => {
            dispatch({
                type: SET_POSTS,
                payload: null,
            });
        });
};

export const getFilteredPosts = (search) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get(applyQueryParams("/search", search))
        .then((res) => {
            dispatch({
                type: SET_FILTERED_POSTS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_FILTERED_POSTS,
                payload: [],
            });
        });
};

export const getRegions = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get("/regions")
        .then((res) => {
            dispatch({
                type: SET_REGIONS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_REGIONS,
                payload: [],
            });
        });
};

// export const getFilteredPostsLikeMe = () => (dispatch) => {};

export const getTopWords = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
        .get("/words/top")
        .then((res) => {
            dispatch({
                type: SET_WORDS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: SET_WORDS,
                payload: err.response.data,
            });
        });
};

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
