import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import MyButton from "../../util/MyButton";
import ProfileSkeleton from "../../util/ProfileSkeleton";
// MUI stuff
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
//Redux
import { connect } from "react-redux";
import { logoutUser, uploadImage } from "../../redux/actions/userActions";
import ProfileIcon from "../icons/ProfileIcon";
import { getUserData } from "../../redux/actions/dataActions";

import "./profile.scss";
import "../../styles/main.scss";
import SubmitReasons from "./SubmitReasons";
import ReasonsMarkup from "./ReasonsMarkup";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            posts: null,
            editReasons: false,
            reasons: [],
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        const handle = this.props.user.credentials.handle;
        this.props.getUserData(handle);
        this.handleScroll();
    }

    componentDidUpdate = () => this.handleScroll();

    handleScroll = () => {
        // const { index, selected } = this.props;
        // if (index === selected) {
        //     setTimeout(() => {
        //         return this.myRef && this.myRef.current
        //             ? this.myRef.current.scrollIntoView(true, {
        //                   behavior: "smooth",
        //               })
        //             : null;
        //     }, 500);
        // }
    };

    handleImageChange = (event) => {
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append("image", image, image.name);
        this.props.uploadImage(formData);
    };
    handleEditPicture = () => {
        const fileInput = document.getElementById("imageInput");
        fileInput.click();
    };
    handleLogout = () => {
        this.props.logoutUser();
    };
    editReasons = (index) => {
        this.setState({ reasonToEdit: index });
        this.setState({ editReasons: true });
    };
    render() {
        const { editReasons } = this.state;
        const {
            user: {
                credentials: { email, handle },
                loading,
            },
        } = this.props;
        const { posts } = this.props.data;

        const filteredPosts =
            posts && posts.filter(({ userHandle }) => userHandle === handle);

        let profileMarkup =
            !loading &&
            !this.props.data.loading &&
            filteredPosts &&
            filteredPosts.length ? (
                <div ref={this.myRef} className="profile-popup">
                    <Grid
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{
                            marginBottom: "20px",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className="heading">
                            Welcome back
                            {this.props.user.credentials.name
                                ? ", " + this.props.user.credentials.name
                                : ""}
                            !
                        </div>
                        <ProfileIcon color="#e41717" size={60} />
                        <div className="email">{email}</div>
                    </Grid>
                    <Grid
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <ReasonsMarkup
                            filteredPosts={filteredPosts}
                            editReasons={this.editReasons}
                        />
                        <MyButton tip="Logout" onClick={this.handleLogout}>
                            <KeyboardReturn color="primary" />
                        </MyButton>
                    </Grid>
                </div>
            ) : !loading && !this.props.data.loading ? (
                <div ref={this.myRef} className="profile-popup">
                    <Grid
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{
                            marginBottom: "20px",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className="heading">
                            Welcome back
                            {this.props.user.credentials.name
                                ? ", " + this.props.user.credentials.name
                                : ""}
                            !
                        </div>
                        <ProfileIcon color="#e41717" size={60} />
                        <div className="email">{email}</div>
                    </Grid>
                    <Grid
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <h2>No posts found.</h2>
                        <MyButton tip="Logout" onClick={this.handleLogout}>
                            <KeyboardReturn color="primary" />
                        </MyButton>
                    </Grid>
                </div>
            ) : (
                <div ref={this.myRef} className="profile-popup">
                    <Grid
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{
                            marginBottom: "20px",
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className="progress-container">
                            <CircularProgress
                                size={60}
                                className="progress-bar"
                            />
                        </div>
                    </Grid>
                </div>
            );

        return editReasons ? (
            <SubmitReasons
                reasons={filteredPosts[this.state.reasonToEdit].body}
                userHandle={filteredPosts[this.state.reasonToEdit].userHandle}
                postId={filteredPosts[this.state.reasonToEdit].postId}
            />
        ) : (
            profileMarkup
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.data,
    user: state.user,
});

const mapActionsToProps = { logoutUser, uploadImage, getUserData };

Profile.propTypes = {
    getUserData: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(Profile);
