import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import cn from "classnames";
import { withRouter } from "react-router";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
// Redux stuff
import { connect } from "react-redux";
import { loginUser, getSignupOptions } from "../../redux/actions/userActions";

const styles = (theme) => ({
    ...theme,
});

class login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errors: {},
        };
    }

    componentDidMount() {
        this.props.getSignupOptions();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password,
        };
        this.props.loginUser(userData, this.props.history);
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    render() {
        const {
            classes,
            UI: { loading },
            goToSignUp,
        } = this.props;
        const { errors } = this.state;

        return (
            <Grid container className={cn("auth login", classes.form)}>
                <Grid item sm>
                    <Typography className={classes.pageTitle}>
                        Sign in
                    </Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            className={classes.textField}
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            fullWidth
                            color="#0f2444"
                        />
                        <TextField
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            className={classes.textField}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={this.state.password}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        {errors.general && (
                            <Typography
                                variant="body2"
                                className={classes.customError}
                            >
                                {errors.general}
                            </Typography>
                        )}
                        <Grid
                            className="buttons"
                            alignItems="center"
                            justify="space-between"
                        >
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                className={cn(
                                    classes.button,
                                    "login-button button"
                                )}
                                disabled={loading}
                                large="large"
                            >
                                Sign In
                                {loading && (
                                    <CircularProgress
                                        size={30}
                                        className={classes.progress}
                                    />
                                )}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={cn(classes.button, "sign-up button")}
                                onClick={goToSignUp}
                            >
                                Create an account
                                {/* {loading && (
                                    <CircularProgress
                                        size={30}
                                        className={classes.progress}
                                    />
                                )} */}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

login.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    goToSignUp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

const mapActionsToProps = {
    loginUser,
    getSignupOptions,
};

export default withRouter(
    connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(login))
);
