import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import jwtDecode from "jwt-decode";
import axios from "axios";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";
// Components
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import themeObject from "./util/theme";
import AuthRoute from "./util/AuthRoute";
import ScrollToTop from "./util/ScrollToTop";
// Pages
import home from "./pages/home";
// import login from "./pages/login";
// import signup from "./pages/signup";
import user from "./pages/user";
import reasons from "./pages/reasons";
import about from "./pages/about";
import contribute from "./pages/contribute";
import FourZeroFour from "./pages/FourZeroFour";

import "./styles/main.scss";
import "./App.css";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";

const theme = createMuiTheme(themeObject);

// axios.defaults.baseURL = "http://localhost:8080/reasons-6a206/us-central1/api/";
axios.defaults.baseURL =
    "https://us-central1-reasons-6a206.cloudfunctions.net/api/";

// axios.defaults.headers = { 'Access-Control-Allow-Origin': '*' };

const token = localStorage.FBIdToken;
if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
        store.dispatch(logoutUser());
        window.location.href = "/";
    } else {
        store.dispatch({ type: SET_AUTHENTICATED });
        axios.defaults.headers.common.Authorization = token;
        store.dispatch(getUserData());
    }
}

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <Router>
                        <ScrollToTop>
                            <Navbar />
                            <div className="container">
                                <Switch>
                                    <Route exact path="/" component={home} />
                                    <AuthRoute
                                        exact
                                        path="/"
                                        component={Login}
                                    />
                                    {/* <AuthRoute
                                        exact
                                        path="/"
                                        component={SignUp}
                                    /> */}
                                    {/* <Route
                                        exact
                                        path="/users/:handle"
                                        component={user}
                                    /> */}
                                    <Route
                                        exact
                                        path="/reasons"
                                        component={reasons}
                                    />
                                    <Route
                                        exact
                                        path="/about"
                                        component={about}
                                    />
                                    <Route
                                        exact
                                        path="/contribute"
                                        component={contribute}
                                    />
                                    <Route
                                        exact
                                        path="/users/:handle/post/:postId"
                                        component={user}
                                    />
                                    <Route component={FourZeroFour} />
                                </Switch>
                                <Footer />
                            </div>
                        </ScrollToTop>
                    </Router>
                </Provider>
            </MuiThemeProvider>
        );
    }
}

export default App;
