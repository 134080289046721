import React from "react";
import { string, number } from "prop-types";

import { blue } from "../../../styles/constants.scss";
import "./background-opacity.scss";

const BackgroundOpacity = ({ background, opacity, opacityColor }) => (
    <>
        <img className="background-image layer" src={background} alt="bkg" />
        <div
            className="bkg-opacity layer"
            style={{ opacity, backgroundColor: opacityColor }}
        />
    </>
);

BackgroundOpacity.propTypes = {
    background: string.isRequired,
    opacity: number,
    opacityColor: string,
};

BackgroundOpacity.defaultProps = {
    opacity: 0.6,
    opacityColor: blue,
};

export default BackgroundOpacity;
