import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

class ContributeButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: this.props.active,
        };
        this.changeColor = this.changeColor.bind(this);
    }

    changeColor() {
        this.setState({ clicked: !this.state.clicked });
    }

    render() {
        let bgColor = this.state.clicked ? "#0f2444" : "#2650d4";
        return (
            <>
                <Button
                    className={this.props.attrs}
                    style={{ backgroundColor: bgColor }}
                    onClick={this.changeColor}
                >
                    ${this.props.amount}
                </Button>
            </>
        );
    }
}

ContributeButton.propTypes = {
    active: PropTypes.bool.isRequired,
    attrs: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
};

export default ContributeButton;
