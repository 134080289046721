import dayjs from "dayjs";

const ELECTION_DAY = "Nov 3 2020";

export const calculateDaysLeft = () => {
    const futureDate = dayjs(ELECTION_DAY);
    const now = dayjs();

    const days = futureDate.diff(now, "days");
    if (days < 0) {
        return 0;
    }

    return days;
};
