import React from "react";

import { blue } from "../../styles/constants.scss";

const EmailIcon = ({ color = blue }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34.16 23.85"
        width={25}
        height={16}
        className="email-icon"
    >
        {/* <title>Asset 10</title> */}
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    className="cls-1"
                    d="M1.28,0,11.6,9.76a8,8,0,0,0,11,0L32.88,0H1.28Z"
                    fill={color}
                />
                <path
                    className="cls-1"
                    d="M0,22.56,9.87,10.45,0,1.14a1.24,1.24,0,0,0,0,.27V22.56Z"
                    fill={color}
                />
                <path
                    className="cls-1"
                    d="M24.69,10.07,34.16,21.7V1.41a2.45,2.45,0,0,0,0-.27l-9.45,8.93Z"
                    fill={color}
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M33.54,23.61,23.45,11.23a9.66,9.66,0,0,1-12.32.35l-10,12.25a1.1,1.1,0,0,0,.26,0H32.75a1.44,1.44,0,0,0,.79-.24Z"
                />
            </g>
        </g>
    </svg>
);

export default EmailIcon;
