import React from "react";

const FacebookIcon = ({ color = "#fff" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="22"
        viewBox="0 0 13.23 28.67"
        fill={color}
    >
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    className="cls-1"
                    d="M3,28.67H8.78V14.21h4l.43-4.84H8.78V6.62C8.78,5.47,9,5,10.11,5h3.12V0h-4C4.94,0,3,1.89,3,5.51V9.37H0v4.9H3Z"
                />
            </g>
        </g>
    </svg>
);

export default FacebookIcon;
