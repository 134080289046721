import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import ReactWordcloud from "react-wordcloud";
import { select } from "d3-selection";
import "d3-transition";
import CircularProgress from "@material-ui/core/CircularProgress";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

const options = {
    colors: ["#fff"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "Times New Roman",
    fontSizes: [50, 200],
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 2,
    rotations: 0,
    rotationAngles: [0, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
};

function getCallback(callback) {
    return function (word, event) {
        const elementFontSize = parseInt(
            event.currentTarget.attributes
                .getNamedItem("font-size")
                .value.replace(/[^\d-]/g, "")
        );
        const isActive = callback !== "onWordMouseOut";
        const element = event.target;
        const text = select(element);
        text.on("click", () => {
            if (isActive) {
                //     // window.open(`https://duckduckgo.com/?q=${word.text}`, "_blank");
            }
        })
            .transition()
            .attr("background", "white")
            .style(
                "font-size",
                isActive
                    ? (elementFontSize * 1.1).toString() + "px"
                    : elementFontSize.toString() + "px"
            )
            .attr("text-decoration", isActive ? "underline" : "none");
    };
}

const callbacks = {
    // getWordColor: (word) => (word.value > 50 ? "orange" : "purple"),
    getWordTooltip: (word) =>
        `${word.value} people included "${word.text}" in their Top7.`,
    onWordClick: getCallback("onWordClick"),
    onWordMouseOut: getCallback("onWordMouseOut"),
    onWordMouseOver: getCallback("onWordMouseOver"),
};

class WordCloud extends Component {
    render() {
        const { words, loading } = this.props;
        let formattedWords = [];
        for (const word in words) {
            formattedWords.push({
                text: this.props.words[word].word.toUpperCase(),
                value: this.props.words[word].value,
            });
        }
        return (
            <>
                {!loading ? (
                    <div style={{ width: "100%", height: "100%" }}>
                        <ReactWordcloud
                            callbacks={callbacks}
                            options={options}
                            words={formattedWords}
                        />
                    </div>
                ) : (
                    <div className="progress-container">
                        <CircularProgress size={60} className="progress-bar" />
                    </div>
                )}
            </>
        );
    }
}

WordCloud.propTypes = {
    // getPosts: PropTypes.func.isRequired,
    words: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default WordCloud;
