import React from "react";
import { string } from "prop-types";
import { logoRed } from "../../styles/constants.scss";

const Logo = ({ color = "#fff" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 235.21 67.5"
        width={188}
        height={54}
    >
        {/* <title>Asset 2</title> */}
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    className="cls-1"
                    d="M0,29.33V23.2H18v6.13H12.56V50.87H5.35V29.33Z"
                    fill={color}
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M19.3,30.43A11.74,11.74,0,0,1,21,26,6.73,6.73,0,0,1,24,23.45a12,12,0,0,1,4.65-.79,12.08,12.08,0,0,1,4.65.79A6.73,6.73,0,0,1,36.39,26a11.74,11.74,0,0,1,1.67,4.46,37.64,37.64,0,0,1,.5,6.61,37.8,37.8,0,0,1-.5,6.61,11.69,11.69,0,0,1-1.67,4.45,6.36,6.36,0,0,1-3.06,2.46,13,13,0,0,1-4.65.74A12.88,12.88,0,0,1,24,50.56,6.36,6.36,0,0,1,21,48.1a11.69,11.69,0,0,1-1.67-4.45A36.85,36.85,0,0,1,18.8,37,36.7,36.7,0,0,1,19.3,30.43Zm7,11.24a18.4,18.4,0,0,0,.33,2.93,2.91,2.91,0,0,0,.74,1.51,2.22,2.22,0,0,0,2.6,0,2.84,2.84,0,0,0,.73-1.51A16.32,16.32,0,0,0,31,41.67c.05-1.23.08-2.77.08-4.63s0-3.41-.08-4.63a16.21,16.21,0,0,0-.33-2.93A2.8,2.8,0,0,0,30,28a2.18,2.18,0,0,0-2.6,0,2.87,2.87,0,0,0-.74,1.51,18.26,18.26,0,0,0-.33,2.93c0,1.22-.07,2.77-.07,4.63S26.26,40.44,26.31,41.67Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M51.7,23.2a8.89,8.89,0,0,1,3.57.66,7,7,0,0,1,2.5,1.8,7.4,7.4,0,0,1,1.45,2.7,11.64,11.64,0,0,1,.46,3.37q0,4.31-2.3,6.45c-1.54,1.44-3.79,2.15-6.77,2.15H48.1V50.87H40.89V23.2ZM49.45,35.14a2.6,2.6,0,0,0,2.08-.82,3.83,3.83,0,0,0,.71-2.55q0-3.37-2.79-3.37H48.1v6.74Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M99.76,39.35a10,10,0,0,1,3.5.54,6.23,6.23,0,0,1,2.37,1.53A5.86,5.86,0,0,1,107,43.77a10.24,10.24,0,0,1,.4,2.94,8.07,8.07,0,0,1-1.12,4.5,4.94,4.94,0,0,1-3.61,2.09v.08a4.32,4.32,0,0,1,3.57,1.82,8.37,8.37,0,0,1,1,4.38v3.49c0,.39,0,.76,0,1.1a6.3,6.3,0,0,0,.12,1,2.3,2.3,0,0,0,.19.66c.1.16.21.31.31.45a1.54,1.54,0,0,0,.5.41V67h-7.67a4.7,4.7,0,0,1-.41-1.28,14.78,14.78,0,0,1-.19-1.47c0-.49-.07-1-.08-1.41s0-.82,0-1.11V59.89A14.86,14.86,0,0,0,99.84,58a3.23,3.23,0,0,0-.41-1.22,1.63,1.63,0,0,0-.74-.62,3.18,3.18,0,0,0-1.1-.17h-1.9V67H88.48V39.35ZM97.12,51.06a2.77,2.77,0,0,0,2.29-.92,3.66,3.66,0,0,0,.73-2.38,3.48,3.48,0,0,0-.71-2.48,3.18,3.18,0,0,0-2.35-.74H95.69v6.52Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M127.12,39.35v5.89h-9.19V50h8.61v5.66h-8.61v5.5h9.54V67H110.73V39.35Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M143.71,39.35,150.45,67h-7.67L142,62.18H136.3L135.57,67H128l6.63-27.67ZM139.13,45l-2,11.74h4l-2-11.74Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M162.43,46.68a4.13,4.13,0,0,0-.51-2.12,1.66,1.66,0,0,0-1.55-.87,1.94,1.94,0,0,0-1.7.76,3,3,0,0,0,.33,3.83,8.05,8.05,0,0,0,2.19,1.38c.87.39,1.82.78,2.82,1.18a12.62,12.62,0,0,1,2.83,1.57A7.76,7.76,0,0,1,169,55a8.41,8.41,0,0,1,.88,4.09q0,4.41-2.52,6.41t-7.29,2a18.81,18.81,0,0,1-4-.37,7,7,0,0,1-2.91-1.28,5.67,5.67,0,0,1-1.8-2.38,9.47,9.47,0,0,1-.62-3.64v-1h7v.66a3.71,3.71,0,0,0,.66,2.5,2.24,2.24,0,0,0,1.71.71,2.06,2.06,0,0,0,1.72-.77,3,3,0,0,0,.61-1.9,2.81,2.81,0,0,0-.84-2.15,7.48,7.48,0,0,0-2.09-1.36c-.84-.37-1.75-.76-2.73-1.14a12,12,0,0,1-2.74-1.51A7.5,7.5,0,0,1,152,51.4a8.07,8.07,0,0,1-.83-3.95c0-2.81.75-5,2.25-6.43s3.77-2.21,6.82-2.21,5.43.68,6.84,2,2.11,3.52,2.11,6.49h-6.74Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M172.15,46.58a11.74,11.74,0,0,1,1.67-4.46,6.73,6.73,0,0,1,3.06-2.52,14.08,14.08,0,0,1,9.3,0,6.73,6.73,0,0,1,3.06,2.52,11.58,11.58,0,0,1,1.67,4.46,36.61,36.61,0,0,1,.51,6.61,36.76,36.76,0,0,1-.51,6.61,11.53,11.53,0,0,1-1.67,4.45,6.36,6.36,0,0,1-3.06,2.46,15,15,0,0,1-9.3,0,6.36,6.36,0,0,1-3.06-2.46,11.69,11.69,0,0,1-1.67-4.45,37.8,37.8,0,0,1-.5-6.61A37.64,37.64,0,0,1,172.15,46.58Zm7,11.24a16.08,16.08,0,0,0,.33,2.92,2.84,2.84,0,0,0,.73,1.52,2.22,2.22,0,0,0,2.6,0,2.91,2.91,0,0,0,.74-1.52,18.11,18.11,0,0,0,.33-2.92c0-1.23.07-2.77.07-4.63s0-3.41-.07-4.63a18.26,18.26,0,0,0-.33-2.93,2.87,2.87,0,0,0-.74-1.51,2.18,2.18,0,0,0-2.6,0,2.8,2.8,0,0,0-.73,1.51,16.21,16.21,0,0,0-.33,2.93c0,1.22-.08,2.77-.08,4.63S179.12,56.59,179.17,57.82Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M202.07,39.35l4.65,17h.08v-17h6.75V67h-7.91l-4.81-17.09h-.07V67H194V39.35Z"
                />
                <path
                    className="cls-1"
                    fill={color}
                    d="M227.73,46.68a4.23,4.23,0,0,0-.5-2.12,1.68,1.68,0,0,0-1.55-.87,1.94,1.94,0,0,0-1.71.76,2.81,2.81,0,0,0-.54,1.68,2.76,2.76,0,0,0,.87,2.15,8.05,8.05,0,0,0,2.19,1.38c.88.39,1.82.78,2.83,1.18a12.84,12.84,0,0,1,2.83,1.57A7.76,7.76,0,0,1,234.34,55a8.41,8.41,0,0,1,.87,4.09q0,4.41-2.52,6.41t-7.29,2a18.88,18.88,0,0,1-4-.37,7,7,0,0,1-2.91-1.28,5.84,5.84,0,0,1-1.8-2.38,9.47,9.47,0,0,1-.62-3.64v-1h7v.66a3.71,3.71,0,0,0,.66,2.5,2.22,2.22,0,0,0,1.7.71,2.08,2.08,0,0,0,1.73-.77,3,3,0,0,0,.6-1.9,2.81,2.81,0,0,0-.84-2.15,7.34,7.34,0,0,0-2.09-1.36c-.84-.37-1.75-.76-2.73-1.14a12.11,12.11,0,0,1-2.73-1.51,7.52,7.52,0,0,1-2.1-2.45,8.19,8.19,0,0,1-.83-3.95c0-2.81.75-5,2.25-6.43s3.77-2.21,6.82-2.21,5.43.68,6.84,2,2.11,3.52,2.11,6.49h-6.74Z"
                />
                <path
                    className="cls-2"
                    fill={logoRed}
                    d="M93.92,0V13.52a101.91,101.91,0,0,0-6.66,12.3,137.83,137.83,0,0,0-5.45,13.77,125.57,125.57,0,0,0-3.89,14.25,103.85,103.85,0,0,0-2,13.66H57.25A114.36,114.36,0,0,1,59.49,54a119.71,119.71,0,0,1,3.93-13.66,108.25,108.25,0,0,1,5.69-13.23A91.88,91.88,0,0,1,76.7,14.78H49V0Z"
                />
            </g>
        </g>
    </svg>
);

Logo.propTypes = {
    color: string,
};

export default Logo;
