import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "reactjs-popup/dist/index.css";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import {
    TWITTER_URL,
    FACEBOOK_URL,
    INSTAGRAM_URL,
} from "../../constants/constants";
import AuthModal from "../auth/AuthModal";
import PopupComponent from "../common/Popup/PopupComponent";
import ProfilePopup from "../profile/ProfilePopup";

// Icons
import FacebookIcon from "../icons/FacebookIcon";
import ProfileIcon from "../icons/ProfileIcon";
import InstagramIcon from "../icons/InstagramIcon";
import TwitterIcon from "../icons/TwitterIcon";
import Logo from "../icons/Logo";

import "./navbar.scss";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false, menuOpen: false };
        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.wrapperRef = React.createRef();
        //this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */

    handleCloseMenu = () => {
        this.setState({ menuOpen: false });
    };

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.handleCloseMenu();
        }
    }

    handleClickMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    toggleModal() {
        let open = this.state.modalOpen;
        this.setState({ modalOpen: !open });
    }

    closeModal() {
        this.setState({ modalOpen: false });
    }

    componentWillReceiveProps(props) {
        if (props.authenticated === true && this.state.modalOpen) {
            this.closeModal();
        }
    }

    render() {
        return (
            <AppBar className="header">
                <Toolbar className="nav-container">
                    <Fragment>
                        <Button
                            color="inherit"
                            component={Link}
                            to="/"
                            className="logo-icon"
                        >
                            <Logo />
                        </Button>
                        <div className="right-content">
                            <Button
                                className="social-media-icon"
                                target="_blank"
                                href={FACEBOOK_URL}
                            >
                                <FacebookIcon />
                            </Button>
                            <Button
                                className="social-media-icon"
                                target="_blank"
                                href={INSTAGRAM_URL}
                            >
                                <InstagramIcon />
                            </Button>
                            <Button
                                className="social-media-icon"
                                target="_blank"
                                href={TWITTER_URL}
                            >
                                <TwitterIcon />
                            </Button>
                            <Link
                                to="/contribute"
                                className="contribute-button medium-desktop-only"
                            >
                                Make a Contribution
                            </Link>
                            <Button onClick={this.toggleModal}>
                                {this.props.authenticated ? (
                                    <ProfileIcon />
                                ) : (
                                    <span className="sign-in-button">
                                        Sign In
                                    </span>
                                )}
                            </Button>
                            <PopupComponent
                                open={this.state.modalOpen}
                                onClose={this.closeModal}
                                children={
                                    this.props.authenticated ? (
                                        <ProfilePopup />
                                    ) : (
                                        <AuthModal />
                                    )
                                }
                                color={
                                    this.props.authenticated ? "blue" : "red"
                                }
                            />
                            <div
                                className="dropdown-container"
                                ref={this.wrapperRef}
                            >
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={this.handleClickMenu}
                                >
                                    <MenuIcon />
                                </Button>
                                {this.state.menuOpen && (
                                    <div className="dropdown-menu">
                                        <div
                                            className="link clickable dropdown-menu-item"
                                            onClick={() => {
                                                this.toggleModal();
                                                this.handleCloseMenu();
                                            }}
                                        >
                                            Submit your Top7
                                        </div>
                                        <Link
                                            className="link dropdown-menu-item"
                                            to="/reasons"
                                        >
                                            Browse Top7s
                                        </Link>
                                        <Link
                                            className="link dropdown-menu-item"
                                            to="/about"
                                        >
                                            About Us
                                        </Link>
                                        <Link
                                            className="link dropdown-menu-item"
                                            to="/contribute"
                                        >
                                            Make a Contribution
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Fragment>
                </Toolbar>
            </AppBar>
        );
    }
}

Navbar.propTypes = {
    authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
});

export default connect(mapStateToProps)(Navbar);
