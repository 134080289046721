import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import {
    TWITTER_URL,
    FACEBOOK_URL,
    INSTAGRAM_URL,
    CONTACT_EMAIL,
} from "../../constants/constants";

import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";
import TwitterIcon from "../icons/TwitterIcon";
import EmailIcon from "../icons/EmailIcon";
import Logo from "../icons/Logo";
import AuthModal from "../auth/AuthModal";
import PopupComponent from "../common/Popup/PopupComponent";

import { blue } from "../../styles/constants.scss";
import SubmitReasons from "../profile/SubmitReasons";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false };
        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    toggleModal() {
        let open = this.state.modalOpen;
        this.setState({ modalOpen: !open });
    }

    closeModal() {
        this.setState({ modalOpen: false });
    }

    componentWillReceiveProps(props) {
        if (props.authenticated === true && this.state.modalOpen) {
            this.closeModal();
        }
    }

    render() {
        return (
            <Grid
                container
                spacing={16}
                direction="column"
                style={{ margin: "none" }}
                className="lastone"
            >
                <BottomNavigation className="footer">
                    <Grid
                        direction="column"
                        justify="center"
                        className="first-column"
                    >
                        <Button color="inherit" component={Link} to="/">
                            <Logo color={blue} />
                        </Button>
                        <Grid>
                            <Button
                                className="social-media-icon"
                                target="_blank"
                                href={FACEBOOK_URL}
                            >
                                <FacebookIcon color={blue} />
                            </Button>
                            <Button
                                className="social-media-icon"
                                target="_blank"
                                href={TWITTER_URL}
                            >
                                <TwitterIcon color={blue} />
                            </Button>
                            <Button
                                className="social-media-icon"
                                target="_blank"
                                href={INSTAGRAM_URL}
                            >
                                <InstagramIcon color={blue} />
                            </Button>
                        </Grid>
                        <div className="copyright">
                            Copyright 2020, all rights reserved.
                        </div>
                    </Grid>
                    <Grid
                        direction="column"
                        className="quotation"
                        alignItems="flex-start"
                        justify="center"
                    >
                        <div
                            className="link clickable"
                            onClick={this.toggleModal}
                        >
                            Submit your Top7
                        </div>
                        <Link className="link" to="/reasons">
                            Browse Top7s
                        </Link>
                        <Link className="link" to="/about">
                            About Us
                        </Link>
                        {/* <div className="link">Go Vote</div> */}
                        <Link className="link" to="/contribute">
                            Make a Contribution
                        </Link>
                        <a
                            href={`mailto:${CONTACT_EMAIL}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <EmailIcon />
                        </a>
                    </Grid>
                    <PopupComponent
                        open={this.state.modalOpen}
                        onClose={this.closeModal}
                        children={
                            this.props.authenticated ? (
                                <SubmitReasons />
                            ) : (
                                <AuthModal />
                            )
                        }
                        color={this.props.authenticated ? "" : "red"}
                    />
                </BottomNavigation>
            </Grid>
        );
    }
}

Footer.propTypes = {
    authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
});

export default connect(mapStateToProps)(Footer);
