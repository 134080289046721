import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import flag from "../assets/flag.png";

class about extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            message: "",
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            email: this.state.email,
            message: this.state.message,
        };
        // TODO: SUBMIT CONTACT MESSAGE
    };

    render() {
        const { errors } = this.state;
        const {
            classes,
            UI: { loading },
        } = this.props;
        return (
            <div className="about-page">
                <img
                    src={flag}
                    alt="United states flag"
                    className="about-page-banner"
                />
                <h1 className="page-title">About Us</h1>
                <div className="about-page-content">
                    <h5>
                        This is about what Donald Trump is doing to the United
                        States, not about us personally. So who we are,
                        specifically, is irrelevant to this cause. In brief,
                        though, we are three American citizens, in different
                        regions of the country, ranging in age from the
                        recently-graduated to being in our 50s. We have no prior
                        political experience or activism.
                    </h5>
                </div>
                <br />
                <div className="about-page-content">
                    <h5>
                        We aren’t a PAC, we aren’t the media, and we don’t have
                        anything to do with either presidential campaign. We
                        have come together simply trying to provide a new way
                        for us all to share our beliefs in a dignified way and
                        learn a thing or two from the other side while we’re at
                        it.
                    </h5>
                </div>
                <br />
                <div className="about-page-content">
                    <h5>
                        Some day, when our grandchildren ask us “what role did
                        YOU play in the 2020 election,” we can point to this
                        site. And we hope that when your grandchildren ask you
                        that same question, you can point to the Top
                        <span className="yellow">7</span> which YOU posted here
                        today.
                    </h5>
                </div>
                <br />
                <br />
                {/* <h1 className="page-title">Contact Us</h1>
                <form noValidate onSubmit={this.handleSubmit}>
                    <TextField
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        className="text-field"
                        helperText={errors.email}
                        error={errors.email ? true : false}
                        value={this.state.email}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <TextField
                        id="message"
                        name="message"
                        type="message"
                        placeholder="Type here"
                        className="text-field"
                        helperText={errors.message}
                        error={errors.message ? true : false}
                        value={this.state.message}
                        onChange={this.handleChange}
                        fullWidth
                        variant="outlined"
                        multiline={true}
                        rows={12}
                    />
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className="contact-button button"
                        disabled={loading}
                    >
                        Submit
                        {loading && (
                            <CircularProgress
                                size={30}
                                className={classes.progress}
                            />
                        )}
                    </Button>
                </form> */}
            </div>
        );
    }
}

about.propTypes = {
    UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    UI: state.UI,
});

export default connect(mapStateToProps, {})(about);
