import React from "react";
import Popup from "reactjs-popup";
import { string } from "prop-types";
import cn from "classnames";

const PopupComponent = ({ children, color, open, onClose }) => (
    <Popup open={open} closeOnDocumentClick={false}>
        <div className={cn("modal", color)}>
            <div className="close-container">
                <a className="close clickable" onClick={onClose}>
                    &times;
                </a>
            </div>
            {children}
        </div>
    </Popup>
);

PopupComponent.propTypes = {
    color: string,
};

export default PopupComponent;
