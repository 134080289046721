import React, { useState } from "react";
import SignUp from "./SignUp";
import Login from "./Login";

import "./auth.scss";

const AuthModal = () => {
    const [showSignUp, setShowSignUp] = useState(false);

    return showSignUp ? (
        <SignUp goToLogin={() => setShowSignUp(false)} />
    ) : (
        <Login goToSignUp={setShowSignUp} />
    );
};

export default AuthModal;
