import React from "react";

const ArrowIcon = ({ upArrow }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 407.437 407.437"
        version="1.1"
        viewBox="0 0 407.437 407.437"
        xmlSpace="preserve"
        width={35}
        height={35}
        style={{
            transform: `rotate(${upArrow ? 0 : 180}deg)`,
            transformOrigin: "50% 50%",
        }}
    >
        <path
            fill="#fff"
            d="M386.258 91.567L203.718 273.512 21.179 91.567 0 112.815 203.718 315.87 407.437 112.815z"
        ></path>
    </svg>
);

export default ArrowIcon;
