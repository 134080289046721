import React, { Component, useState } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { object } from "prop-types";
// MUI Stuff
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
// Icons
import ChatIcon from "@material-ui/icons/Chat";
// Redux
import { connect } from "react-redux";
import LikeButton from "./LikeButton";
import PostDialog from "./PostDialog";
import DeletePost from "./DeletePost";
import MyButton from "../../util/MyButton";
import ProfileIcon from "../icons/ProfileIcon";

import { red } from "../../styles/constants.scss";
import ArrowIcon from "../icons/DownArrow";

const styles = {
    card: {
        position: "relative",
        display: "flex",
        marginBottom: 20,
    },
    image: {
        minWidth: 200,
    },
    content: {
        padding: 25,
        objectFit: "cover",
    },
};

const Post = ({ user, post }) => {
    const [showMore, setShowMore] = useState(false);

    const {
        body,
        createdAt,
        userImage,
        userHandle,
        postId,
        likeCount,
        commentCount,
        userName,
        userState,
        userAge,
        userGender,
        userIncome,
        userViews,
        userVote,
    } = post;

    const {
        authenticated,
        credentials: { handle },
    } = user;

    const deleteButton =
        authenticated && userHandle === handle ? (
            <DeletePost postId={postId} />
        ) : null;

    const toggleMore = () => {
        setShowMore(!showMore);
    };

    const reasons = Array.isArray(body)
        ? body
              .map((reason, index) => (
                  <div
                      className={showMore ? "reason-expanded" : "reason"}
                      key={`postId-${index}`}
                  >
                      <h5>
                          {index + 1}. {reason}
                      </h5>
                  </div>
              ))
              .filter((reason, index) => index < 3 || showMore)
        : null;

    const getAgeGroup = (age) => {
        if (age < 17) {
            return "17 and under";
        }
        if (age < 30) {
            return "18-29";
        }
        if (age < 50) {
            return "30-49";
        }
        if (age < 70) {
            return "50-69";
        } else return "70+";
    };

    return (
        <Card id={`${postId}-parent`} className="post-card">
            <CardContent className="post-card-content">
                <ProfileIcon color={red} size={50} />
                <div className="info">
                    {userName && userState != null && (
                        <h6 className="name">{`${userName} ${
                            userState !== "International"
                                ? ` from ${userState}`
                                : ` - International Voter`
                        }`}</h6>
                    )}
                    {userName && userState == null && (
                        <h6 className="name">{userName}</h6>
                    )}
                    {!userName &&
                        (userState != null && userState !== "International" ? (
                            <h6 className="name">Voter from {userState}</h6>
                        ) : userState != null &&
                          userState === "International" ? (
                            <h6 className="name">International Voter</h6>
                        ) : (
                            <h6 className="name">Anonymous Voter</h6>
                        ))}
                    {!!userGender && (
                        <h6 className="secondary">Gender: {userGender}</h6>
                    )}
                    {!!userAge && userAge > 0 && (
                        <h6 className="secondary">
                            Age: {getAgeGroup(userAge)}
                        </h6>
                    )}
                    {!!userViews && (
                        <h6 className="secondary">
                            Politically leans: {userViews}
                        </h6>
                    )}
                    {!!userVote && (
                        <h6 className="secondary">
                            As of now probably going to vote: {userVote}
                        </h6>
                    )}
                </div>
                {deleteButton}
                {reasons}
                <LikeButton postId={postId} />
                <div className="clickable centered" onClick={toggleMore}>
                    <h6>{showMore ? "See less" : "See more"}</h6>
                    <ArrowIcon upArrow={!showMore} />
                </div>
            </CardContent>
        </Card>
    );
};

Post.propTypes = {
    user: object.isRequired,
    post: object.isRequired,
};

export default Post;
