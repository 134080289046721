import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import cn from "classnames";
import { withRouter } from "react-router";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Redux stuff
import { connect } from "react-redux";
import {
    postPost,
    editPost,
    clearErrors,
} from "../../redux/actions/dataActions";
import "./submit-reasons.scss";
import { red, blue } from "../../styles/constants.scss";

const styles = (theme) => ({
    ...theme,
});

class submitReasons extends Component {
    constructor(props) {
        super(props);

        if (
            props.reasons &&
            Array.isArray(props.reasons) &&
            props.reasons.length
        ) {
            this.state = {
                reason1: props.reasons[0],
                reason2: props.reasons[1],
                reason3: props.reasons[2],
                reason4: props.reasons[3],
                reason5: props.reasons[4],
                reason6: props.reasons[5],
                reason7: props.reasons[6],
                incompleteError: true,
                success: false,
                errors: {},
            };
        } else {
            this.state = {
                reason1: "",
                reason2: "",
                reason3: "",
                reason4: "",
                reason5: "",
                reason6: "",
                reason7: "",
                incompleteError: true,
                success: false,
                errors: {},
            };
        }

        this.handleChange.bind(this);
        this.handleSubmit.bind(this);
        this.goToReasons = this.goToReasons.bind(this);
        // this.populateReasons = this.populateReasons.bind(this);
    }

    componentDidMount() {
        if (
            this.props.reasons &&
            Array.isArray(this.props.reasons) &&
            this.props.reasons.length
        ) {
            this.setState({
                reason1: this.props.reasons[0],
                reason2: this.props.reasons[1],
                reason3: this.props.reasons[2],
                reason4: this.props.reasons[3],
                reason5: this.props.reasons[4],
                reason6: this.props.reasons[5],
                reason7: this.props.reasons[6],
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        // if (this.props.reasons != nextProps.reasons) {
        //     this.setState({
        //         reason1: this.props.reasons[0],
        //         reason2: this.props.reasons[1],
        //         reason3: this.props.reasons[2],
        //         reason4: this.props.reasons[3],
        //         reason5: this.props.reasons[4],
        //         reason6: this.props.reasons[5],
        //         reason7: this.props.reasons[6],
        //     });
        // }
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (nextProps.reasons !== this.props.reasons) {
    //         return true;
    //     }
    //     return false;
    // }

    handleSubmit = async (event) => {
        event.preventDefault();
        const reasons = [
            this.state.reason1,
            this.state.reason2,
            this.state.reason3,
            this.state.reason4,
            this.state.reason5,
            this.state.reason6,
            this.state.reason7,
        ];

        if (reasons.includes("")) {
            this.setState({
                errors: {
                    general: "You must fill out all seven reasons to continue.",
                },
            });
            return;
        }

        var data;
        if (this.props.reasons) {
            data = await this.props.editPost({
                body: reasons,
                postId: this.props.postId,
                userHandle: this.props.userHandle,
            });
        } else {
            data = await this.props.postPost({ body: reasons });
        }

        if (data.complete) {
            this.setState({
                success: true,
            });
            setTimeout(this.goToReasons, 1500);
        } else {
            this.setState({
                errors: {
                    general: "Something went wrong. Please try again!",
                },
            });
        }
    };

    goToReasons() {
        this.props.history.push("/reasons");
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    render() {
        const {
            classes,
            UI: { loading },
            goToSignUp,
            reasons,
        } = this.props;
        const { errors } = this.state;

        // console.log(reasons);
        // console.log(this.state.reason1);

        return (
            <Grid container className={cn("submit-reasons", classes.form)}>
                <Grid item sm>
                    <h3>Submit Your 7</h3>
                    <img src="//trends.revcontent.com/conv.php?t=eiYnWEyJAOsgO72mNQy1JbrvqnCMq6mpVFmTnhjrOGkeUB9H%2Fqdjrd2hCbWLGIpC" />
                    <h5 className="message">
                        What should you tell your fellow citizens? Any issue
                        regarding Trump you feel strongly about! International?
                        Domestic? Personal? Legal? Leadership? It’s whatever YOU
                        believe they should keep in mind as they go vote. Pick
                        your Top7 Reasons you feel he should or shouldn’t get 4
                        more years.
                        <br />
                        <br />
                        You can Check Out Top7s written by others for
                        inspiration.
                        <br />
                        <br />
                        Each of your Top7 can be just a few words, or a few
                        paragraphs. We ask that you please “keep it clean” and
                        courteous.
                    </h5>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            id="reason1"
                            name="reason1"
                            type="text"
                            label="#1"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            helperText={errors.reason1}
                            error={errors.reason1 ? true : false}
                            value={this.state.reason1}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />
                        <TextField
                            id="reason2"
                            name="reason2"
                            type="text"
                            label="#2"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            helperText={errors.reason2}
                            error={errors.reason2 ? true : false}
                            value={this.state.reason2}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />

                        <TextField
                            id="reason3"
                            name="reason3"
                            type="text"
                            label="#3"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            helperText={errors.reason3}
                            error={errors.reason3 ? true : false}
                            value={this.state.reason3}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />

                        <TextField
                            id="reason4"
                            name="reason4"
                            type="text"
                            label="#4"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            helperText={errors.reason4}
                            error={errors.reason4 ? true : false}
                            value={this.state.reason4}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />

                        <TextField
                            id="reason5"
                            name="reason5"
                            type="text"
                            label="#5"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            helperText={errors.reason5}
                            error={errors.reason5 ? true : false}
                            value={this.state.reason5}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />

                        <TextField
                            id="reason6"
                            name="reason6"
                            type="text"
                            label="#6"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            helperText={errors.reason6}
                            error={errors.reason6 ? true : false}
                            value={this.state.reason6}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />
                        <TextField
                            id="reason7"
                            name="reason7"
                            type="text"
                            label="#7"
                            placeholder="type here"
                            className={cn(classes.textField, "text-field")}
                            error={errors.reason7 ? true : false}
                            value={this.state.reason7}
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rowsMax={4}
                        />
                        {errors.general && (
                            <Typography
                                variant="body2"
                                className={classes.customError}
                            >
                                {errors.general}
                            </Typography>
                        )}
                        <Grid
                            className="submit-reasons-buttons"
                            alignItems="center"
                            justify="center"
                        >
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                className={cn(
                                    classes.button,
                                    "post-post button"
                                )}
                                disabled={loading}
                                large="large"
                            >
                                {!this.state.success ? "Submit" : "Success!"}
                                {loading && (
                                    <CircularProgress
                                        size={30}
                                        className={classes.progress}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

submitReasons.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    goToSignUp: PropTypes.func.isRequired,
    reasons: PropTypes.array,
    userHandle: PropTypes.string,
    postId: PropTypes.string,
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

const mapActionsToProps = {
    postPost,
    editPost,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsToProps
    )(withStyles(styles)(submitReasons))
);
