import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import Post from "../components/post/Post";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getFilteredPosts, getRegions } from "../redux/actions/dataActions";
import { getSignupOptions } from "../redux/actions/userActions";

class reasons extends Component {
    constructor() {
        super();
        this.state = {
            region: "",
            gender: "",
            age: "",
            views: "",
            vote: "",
        };
        this.handleChange.bind(this);
        this.componentWillUpdate.bind(this);
        this.componentDidMount.bind(this);
        this.setUndefined.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.history);
        this.props.getFilteredPosts();
        this.props.getSignupOptions();
        this.props.getRegions();
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    setUndefined = (value) => (value === "" ? undefined : value);

    componentWillUpdate(nextProps, nextState) {
        if (
            nextState.region !== this.state.region ||
            nextState.gender !== this.state.gender ||
            nextState.age !== this.state.age ||
            nextState.views !== this.state.views ||
            nextState.vote !== this.state.vote
        ) {
            const search = {
                region: this.setUndefined(nextState.region),
                gender: this.setUndefined(nextState.gender),
                age: this.setUndefined(nextState.age),
                views: this.setUndefined(nextState.views),
                vote: this.setUndefined(nextState.vote),
            };
            this.props.getFilteredPosts(search);
        }
        // if (nextState.onDelete === true) {
        //     this.setState({ postDeleted: false });
        // }
    }

    // onPostDelete = () => {
    //     console.log("A");
    //     this.setState({ postDeleted: true });
    // };

    render() {
        const { filteredPosts: posts, loading } = this.props.data;
        const isMobile = window && window.innerWidth < 440;

        const getPost = (post) => (
            <Grid item xs={12} sm={12}>
                <Post key={post.postId} post={post} user={this.props.user} />
            </Grid>
        );

        const recentPostsFirstColumn = posts
            .filter((post, index) => isMobile || index % 2 === 0)
            .map((post) => {
                return getPost(post);
                // if (this.props.user.credentials.handle !== post.userHandle) {
                //     return getPost(post);
                // }
            });

        const recentPostsSecondColumn = posts
            .filter((post, index) => index % 2 === 1)
            .map((post) => {
                return getPost(post);
                // if (this.props.user.credentials.handle !== post.userHandle) {
                //     return getPost(post);
                // }
            });

        const getSelectOptions = (options) => {
            return options
                ? options.map((option) => (
                      <MenuItem value={option} key={option}>
                          {option}
                      </MenuItem>
                  ))
                : null;
        };

        return (
            <Grid
                container
                spacing={16}
                className="reasons-grid"
                direction="column"
                alignItems="center"
            >
                <h1 className="page-title">
                    Top<span className="yellow">7</span>s
                </h1>

                <img src="//trends.revcontent.com/conv.php?t=4r1dDCVqw5L6Er6G14n29KeGQfR%2BIwkqntmd1yLTbwRgkhRBrLCCoOq6q%2B9Rx748" />
                <div className="label">
                    Choose the type of voter whose Top 7s you’d like to see. Or,
                    just start reading below.
                </div>
                <Grid className="filters">
                    <FormControl className="form-control">
                        <InputLabel id="region-label">Region</InputLabel>
                        <Select
                            id="region"
                            name="region"
                            labelid="region-label"
                            value={this.state.region}
                            onChange={this.handleChange}
                            className="select"
                            color="secondary"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {getSelectOptions(this.props.data.regions)}
                        </Select>
                    </FormControl>
                    <FormControl
                        className="form-control"
                        style={{ margin: "0px 15px" }}
                    >
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                            id="gender"
                            name="gender"
                            labelid="gender-label"
                            value={this.state.gender}
                            onChange={this.handleChange}
                            className="select"
                            color="secondary"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {getSelectOptions([
                                "Male",
                                "Female",
                                "Transgender",
                                "Non-binary/non-conforming",
                            ])}
                        </Select>
                    </FormControl>
                    <FormControl
                        className="form-control"
                        style={{ margin: "0px 15px" }}
                    >
                        <InputLabel id="age-label">Age</InputLabel>
                        <Select
                            id="age"
                            name="age"
                            labelid="age-label"
                            value={this.state.age}
                            onChange={this.handleChange}
                            className="select"
                            color="secondary"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {getSelectOptions([
                                "17 and under",
                                "18-29",
                                "30-49",
                                "50-69",
                                "70+",
                            ])}
                        </Select>
                    </FormControl>
                    {/* <TextField
                        id="age"
                        name="age"
                        type="search"
                        label="Age"
                        className="text-field"
                        value={this.state.age}
                        onChange={this.handleChange}
                    /> */}
                    <FormControl
                        className="form-control"
                        style={{ margin: "0px 15px" }}
                    >
                        <InputLabel id="views-label">
                            Political Views
                        </InputLabel>
                        <Select
                            id="views"
                            name="views"
                            labelid="views-label"
                            value={this.state.views}
                            onChange={this.handleChange}
                            className="select big"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {/* TODO: Fix this */}
                            {getSelectOptions([
                                "Liberal",
                                "Conservative",
                                "Independent",
                                "Third Party",
                            ])}
                        </Select>
                    </FormControl>
                    <FormControl className="form-control">
                        <InputLabel id="vote-label">Intended Vote</InputLabel>
                        <Select
                            id="vote"
                            name="vote"
                            labelid="vote-label"
                            value={this.state.vote}
                            onChange={this.handleChange}
                            className="select big"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {/* TODO: Fix this */}
                            {getSelectOptions([
                                "Biden/Harris",
                                "Trump/Pence",
                                "Third Party/Write-in",
                                "Undecided",
                                "Not voting",
                            ])}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container sm={12} xs={12} className="content">
                    {!loading && posts.length !== 0 ? (
                        <>
                            <Grid
                                direction="column"
                                item
                                xs={12}
                                sm={6}
                                className="first-col"
                            >
                                {recentPostsFirstColumn}
                            </Grid>
                            <Grid
                                direction="column"
                                item
                                xs={12}
                                sm={6}
                                className="second-col"
                            >
                                {recentPostsSecondColumn}
                            </Grid>
                        </>
                    ) : !loading && posts.length === 0 ? (
                        <div className="progress-container">
                            <h2>No Top7s found for this search.</h2>
                        </div>
                    ) : (
                        <div className="progress-container">
                            <CircularProgress
                                size={60}
                                className="progress-bar"
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        );
    }
}

reasons.propTypes = {
    getFilteredPosts: PropTypes.func.isRequired,
    getSignupOptions: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
    user: state.user,
});

export default withRouter(
    connect(mapStateToProps, {
        getFilteredPosts,
        getSignupOptions,
        getRegions,
    })(reasons)
);
