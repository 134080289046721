import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import Post from "../components/post/Post";
import Profile from "../components/profile/Profile";
import ImageBanner from "../components/home/ImageBanner/ImageBanner";
import PostSkeleton from "../util/PostSkeleton";
import WordCloud from "../components/home/WordCloud";
import withStyles from "@material-ui/core/styles/withStyles";

import { getTopWords, getPosts } from "../redux/actions/dataActions";
import { calculateDaysLeft } from "../util/date";
import ChecklistIcon from "../components/icons/ChecklistIcon";
import BackgroundOpacity from "../components/common/BackgroundOpacity/BackgroundOpacity";
import MediaQuery from "react-responsive";

import GridBackground from "../assets/manifestation.jpg";
import SubmitReasons from "../components/profile/SubmitReasons";
import AuthModal from "../components/auth/AuthModal";
import PopupComponent from "../components/common/Popup/PopupComponent";
import { red, white, blue } from "../styles/constants.scss";

const styles = (theme) => ({
    ...theme,
});

const StyledButton = withStyles({
    // color: $white;
    // background-color: $red;
    // padding: 15px 30px;
    // font-weight: bold;
    // &.blue {
    //     background-color: $dark-blue;
    // }
    root: {
        fontFamily: "acumin-pro-condensed",
        color: white,
        padding: "15px 30px",
        // display: "block",
        textAlign: "center",
        borderRadius: 0,
        fontSize: "40px",
        fontWeight: 800,
        minWidth: "380px",
        height: "auto",
        // },
        // [styles.breakpoints.down("sm")]: {
        //     fontSize: "40px",
        //     fontWeight: 800,
        // },
        // [styles.breakpoints.up("md")]: {
        //     fontSize: "40px",
        //     fontWeight: 800,
        // },
        // [styles.breakpoints.up("lg")]: {
        //     fontSize: "40px",
        //     fontWeight: 800,
        // },
    },
    label: {
        textTransform: "none",
    },
})(Button);

const breakpoints = {
    desktop: "(min-width: 1025px)",
    tablet: "(min-width: 768px) and (max-width: 1024px)",
    phone: "(max-width: 767px)",
};

class home extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false };
        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.goToReasons = this.goToReasons.bind(this);
    }

    componentDidMount() {
        this.props.getPosts();
        this.props.getTopWords();
    }

    toggleModal() {
        let open = this.state.modalOpen;
        this.setState({ modalOpen: !open });
    }

    closeModal() {
        this.setState({ modalOpen: false });
    }

    componentWillReceiveProps(props) {
        if (props.authenticated === true && this.state.modalOpen) {
            // this.closeModal();
        }
    }

    goToReasons() {
        this.props.history.push("/reasons");
    }

    render() {
        const { words, loading, posts } = this.props.data;

        const getRandomItem = () =>
            posts[Math.floor(Math.random() * posts.length)];

        const reducedPosts =
            posts && posts.length > 0
                ? [getRandomItem(), getRandomItem(), getRandomItem()]
                : [];

        // console.log(reducedPosts);

        return (
            <Grid
                container
                spacing={16}
                className="home-page"
                direction="column"
            >
                <Grid
                    direction="column"
                    className="quotation"
                    alignItems="center"
                    justify="center"
                >
                    <span className="italics">
                        "The powerful play goes on, and{" "}
                        <span className="underline">you</span> may contribute a
                        verse.
                    </span>
                    <span>
                        <span className="italics">
                            What will your verse be?"
                        </span>{" "}
                        - Walt Whitman and Robin Williams
                    </span>
                </Grid>
                <ImageBanner />
                <Grid className="action-buttons">
                    <StyledButton
                        className="action-button clickable"
                        onClick={this.toggleModal}
                        variant="contained"
                        style={{
                            backgroundColor: red,
                            minWidth: "unset",
                        }}
                    >
                        Submit Your Top<span className="yellow">7</span>
                    </StyledButton>
                    <img src="//trends.revcontent.com/conv.php?t=PrEcjUwuCLTnYAky4krIvCUzul1dSU4N5yE6uyJKN6dXQWBn50nivfjt3MQ8fxUT" />
                    <StyledButton
                        className="action-button blue clickable"
                        onClick={this.goToReasons}
                        variant="contained"
                        style={{
                            backgroundColor: blue,
                            minWidth: "unset",
                        }}
                    >
                        Check Out Some Top<span className="yellow">7</span>s
                    </StyledButton>
                </Grid>
                <Grid
                    className="home-message red h2 desktop-only"
                    alignItems="center"
                    justify="center"
                >
                    <div>
                        Help persuade others. Let
                        <span className="yellow"> your </span>voice be heard.
                    </div>
                    <div>
                        You have
                        <span className="yellow"> {calculateDaysLeft()} </span>
                        days to make a difference.
                    </div>
                </Grid>
                <Grid
                    className="home-message red h2 mobile-only"
                    alignItems="center"
                    justify="center"
                >
                    <div>
                        <div>
                            Help persuade others. Let
                            <span className="yellow"> your </span>voice be
                            heard.
                        </div>
                        <div>
                            You have
                            <span className="yellow">
                                {" "}
                                {calculateDaysLeft()}{" "}
                            </span>
                            days to make a difference.
                        </div>
                    </div>
                </Grid>
                <Grid
                    container
                    className="grid help"
                    style={{ width: "unset" }}
                >
                    <Grid
                        item
                        xs={6}
                        direction="column"
                        alignItems="center"
                        className="grid-column grid-item column"
                    >
                        <ChecklistIcon />
                        <h2>
                            Let your fellow Americans <br /> hear YOUR reasons!
                        </h2>
                        <h5 className="message h5">
                            Maybe, just maybe, YOUR reasons will strike a chord
                            with some voters. Maybe YOU can be instrumental in
                            tipping the national vote. <br />
                            <br /> You have {calculateDaysLeft()} days left to
                            make an impact. Can't narrow it down to just your
                            Top7? That's okay, there's room for more. Can't come
                            up with a Top7? Get some friends together and submit
                            as a team!
                        </h5>
                        <div
                            className="action-button red h2 clickable"
                            onClick={this.toggleModal}
                        >
                            Submit Your Top<span className="yellow">7</span>
                        </div>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        direction="column"
                        alignItems="center"
                        className="column lol padded-column"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            direction="column"
                            alignItems="center"
                            className="grid-row grid-item blue-bkg first-item"
                        >
                            <h2> Need some inspiration?</h2>
                            <h5 className="message h5 big">
                                See the "word cloud" below of commonly-chosen
                                reasons. Or just check the news to see what
                                Trump has done today. <br />
                                <br />
                                America...let's see your Top7 list of reasons!
                            </h5>
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            direction="column"
                            alignItems="center"
                            className="grid-item blue-bkg second-item"
                            style={{}}
                        >
                            <WordCloud
                                words={words}
                                loading={loading}
                                style={{ all: "unset" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    className="home-message h2 desktop-only"
                    alignItems="center"
                    justify="center"
                >
                    <div>
                        Not sure yet whether he deserves 4 more years? <br />
                        Not sure yet whether it’s worth voting?
                    </div>
                </Grid>
                <Grid
                    className="home-message h2 mobile-only"
                    alignItems="center"
                    justify="center"
                >
                    <div>
                        Not sure yet whether he <br />
                        deserves 4 more years? <br />
                        <br />
                        Not sure yet whether <br />
                        it’s worth voting?
                    </div>
                </Grid>
                <Grid
                    container
                    className="grid help"
                    style={{ width: "unset" }}
                >
                    <Grid
                        item
                        xs={6}
                        direction="column"
                        alignItems="center"
                        className="grid-column grid-item"
                        style={{
                            borderRight: "none",
                            borderBottom: `2px solid ${blue}`,
                        }}
                    >
                        <h2>
                            What do Americans <br />
                            like you think <br /> about Trump?
                        </h2>
                        <h5 className="message">
                            You've read what the Media thinks. <br />
                            You've read what your family thinks. <br />
                            You've read what your friends think. <br />
                            You've read what Social Media thinks. <br />
                            But what about other typical American <br />
                            voters just like you?
                            <br />
                            <br />
                            It’s interesting and helpful to see <br />
                            what other people of your age and gender, <br />
                            in your part of the country are saying <br />
                            So, use the menu here to pick the types <br />
                            of people YOU want to hear from.
                        </h5>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        direction="column"
                        alignItems="center"
                        className="grid-column grid-item relative grid-last grid-with-bkg"
                    >
                        <BackgroundOpacity background={GridBackground} />
                        <div className="grid-overlay">
                            <div>
                                {reducedPosts.map(({ body }, index) => (
                                    <div
                                        className={"reason-expanded"}
                                        key={`postId-${index}`}
                                    >
                                        {!!body && (
                                            <h5>
                                                {index + 1}. {body[index]}
                                            </h5>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <StyledButton
                                className="action-button blue clickable"
                                onClick={this.goToReasons}
                                variant="contained"
                                style={{
                                    backgroundColor: blue,
                                    minWidth: "unset",
                                    fontSize: "30px",
                                }}
                            >
                                Check Out Some Top
                                <span className="yellow">7</span>s
                            </StyledButton>
                        </div>
                    </Grid>
                </Grid>
                <PopupComponent
                    open={this.state.modalOpen}
                    onClose={this.closeModal}
                    children={
                        this.props.authenticated ? (
                            <SubmitReasons />
                        ) : (
                            <AuthModal />
                        )
                    }
                    color={this.props.authenticated ? "" : "red"}
                />
            </Grid>
        );
    }
}

home.propTypes = {
    getPosts: PropTypes.func.isRequired,
    getTopWords: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.data,
    authenticated: state.user.authenticated,
});

export default withRouter(
    connect(mapStateToProps, { getTopWords, getPosts })(
        withStyles(styles)(home)
    )
);
