import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import flag from "../assets/flag.png";

class about extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            message: "",
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { errors } = this.state;
        const {
            classes,
            UI: { loading },
        } = this.props;
        return (
            <div className="about-page" style={{ height: "70vh" }}>
                <img
                    src={flag}
                    alt="United states flag"
                    className="about-page-banner"
                />
                <h1 className="page-title">Page Not Found</h1>
            </div>
        );
    }
}

about.propTypes = {
    UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    UI: state.UI,
});

export default connect(mapStateToProps, {})(about);
