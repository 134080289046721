import React from "react";

const TwitterIcon = ({ color = "#fff" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.27 20.54"
        width={20}
        height={16}
        fill={color}
    >
        {/* <title>Asset 6</title> */}
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    className="cls-1"
                    d="M0,18.21a14.68,14.68,0,0,0,8,2.33A14.66,14.66,0,0,0,22.68,5.11a10.65,10.65,0,0,0,2.59-2.68,10.61,10.61,0,0,1-3,.82A5.22,5.22,0,0,0,24.57.38a10.65,10.65,0,0,1-3.29,1.26,5.19,5.19,0,0,0-8.84,4.73A14.73,14.73,0,0,1,1.76,1a5.19,5.19,0,0,0,1.6,6.92A5.19,5.19,0,0,1,1,7.22a5.2,5.2,0,0,0,4.16,5.15,5.24,5.24,0,0,1-2.34.09,5.19,5.19,0,0,0,4.85,3.6A10.45,10.45,0,0,1,0,18.21Z"
                />
            </g>
        </g>
    </svg>
);

export default TwitterIcon;
