import React from "react";

const InstagramIcon = ({ color = "#fff" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26"
        width={21}
        height={21}
        fill={color}
    >
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    className="cls-1"
                    d="M13,2.34c3.47,0,3.89,0,5.26.08a7.21,7.21,0,0,1,2.41.45,3.93,3.93,0,0,1,1.5,1,4.13,4.13,0,0,1,1,1.49,7.22,7.22,0,0,1,.45,2.42c.06,1.37.07,1.78.07,5.25s0,3.89-.07,5.26a7.21,7.21,0,0,1-.45,2.41,4.26,4.26,0,0,1-2.47,2.47,7.21,7.21,0,0,1-2.41.45c-1.37.06-1.79.07-5.26.07s-3.88,0-5.25-.07a7.22,7.22,0,0,1-2.42-.45,4.13,4.13,0,0,1-1.49-1,3.93,3.93,0,0,1-1-1.5,7.21,7.21,0,0,1-.45-2.41c-.06-1.37-.08-1.79-.08-5.26s0-3.88.08-5.25a7.22,7.22,0,0,1,.45-2.42,3.89,3.89,0,0,1,1-1.49,3.89,3.89,0,0,1,1.49-1,7.22,7.22,0,0,1,2.42-.45c1.37-.06,1.78-.08,5.25-.08M13,0C9.47,0,9,0,7.64.08a9.45,9.45,0,0,0-3.15.6A6.62,6.62,0,0,0,.68,4.49a9.45,9.45,0,0,0-.6,3.15C0,9,0,9.47,0,13s0,4,.08,5.36a9.52,9.52,0,0,0,.6,3.16,6.39,6.39,0,0,0,1.5,2.3,6.29,6.29,0,0,0,2.31,1.5,9.68,9.68,0,0,0,3.15.61C9,26,9.47,26,13,26s4,0,5.36-.07a9.75,9.75,0,0,0,3.16-.61,6.26,6.26,0,0,0,2.3-1.5,6.39,6.39,0,0,0,1.5-2.3,9.75,9.75,0,0,0,.61-3.16C26,17,26,16.53,26,13s0-4-.07-5.36a9.68,9.68,0,0,0-.61-3.15,6.42,6.42,0,0,0-1.5-2.31,6.39,6.39,0,0,0-2.3-1.5,9.52,9.52,0,0,0-3.16-.6C17,0,16.53,0,13,0"
                />
                <path
                    className="cls-1"
                    d="M13,6.33A6.68,6.68,0,1,0,19.68,13,6.66,6.66,0,0,0,13,6.33m0,11A4.34,4.34,0,1,1,17.34,13,4.33,4.33,0,0,1,13,17.34"
                />
                <path
                    className="cls-1"
                    d="M21.5,6.06A1.56,1.56,0,1,1,19.94,4.5,1.56,1.56,0,0,1,21.5,6.06"
                />
            </g>
        </g>
    </svg>
);

export default InstagramIcon;
