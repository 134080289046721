import React from "react";
import { string, number } from "prop-types";

const ProfileIcon = ({ color = "#fff", size = 20 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29.15 29.81"
        width={size}
        height={size}
        fill={color}
    >
        {/* <title>Asset 4</title> */}
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g className="cls-1">
                    <path
                        className="cls-2"
                        d="M14.47,29.8c-3.43,0-6.86,0-10.28,0a3.83,3.83,0,0,1-4-2.56,3.71,3.71,0,0,1,1.48-4.48c2.58-1.63,5.31-3,8-4.47a1.53,1.53,0,0,1,1.14.16,9,9,0,0,0,7.44,0,2,2,0,0,1,1.49,0c2.36,1.2,4.7,2.43,7,3.76a4.09,4.09,0,0,1,2.12,5.09A3.77,3.77,0,0,1,25,29.79q-5.28,0-10.55,0Z"
                    />
                    <path
                        className="cls-2"
                        d="M6.82,9.15c0-5.67,4-9.7,8.72-9.09a7.44,7.44,0,0,1,5.9,4.6,9.51,9.51,0,0,1-1.66,10.58,6.82,6.82,0,0,1-7.16,2C9.53,16.29,7.86,14,7.08,11A16,16,0,0,1,6.82,9.15Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

ProfileIcon.propTypes = {
    color: string,
    size: number,
};

export default ProfileIcon;
