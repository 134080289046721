import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import cn from "classnames";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Redux stuff
import { connect } from "react-redux";
import { signupUser } from "../../redux/actions/userActions";
import { CAPTCHA_KEY } from "../../constants/constants";

const styles = (theme) => ({
    ...theme,
});

class signup extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            handle: "",
            errors: {},
            state: "",
            age: "",
            name: "",
            gender: "",
            views: "",
            vote: "",
            captcha: "",
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.UI.errors) {
            this.setState({ errors: nextProps.UI.errors });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });

        if (this.state.age !== "") {
            const patt = new RegExp("^[0-9]+$");
            if (!patt.test(this.state.age)) {
                this.setState({
                    age: "",
                });
            }
        }

        const newUserData = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            handle: this.state.email,
            name: this.state.name === "" ? null : this.state.name,
            state: this.state.state === "" ? null : this.state.state,
            age: this.state.age === "" ? null : parseInt(this.state.age),
            gender: this.state.gender === "" ? null : this.state.gender,
            views: this.state.views === "" ? null : this.state.views,
            vote: this.state.vote === "" ? null : this.state.vote,
            captcha: this.state.captcha,
        };
        this.props.signupUser(newUserData, this.props.history);
        this.captcha.reset();
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onEnterCaptcha = (value) => {
        this.setState({
            captcha: value,
        });
    };

    render() {
        const {
            classes,
            UI: { loading },
        } = this.props;
        const { errors } = this.state;

        const getSelectOptions = (options) => {
            return options
                ? options.map((option) => (
                      <MenuItem value={option} key={option}>
                          {option}
                      </MenuItem>
                  ))
                : null;
        };

        return (
            <Grid container className={cn("auth signup", classes.form)}>
                <Grid item sm>
                    <Typography className={classes.pageTitle}>
                        Create an account
                    </Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            className={classes.textField}
                            helperText={errors.email || errors.handle}
                            error={errors.email ? true : false}
                            value={this.state.email}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <TextField
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
                            className={classes.textField}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            value={this.state.password}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <TextField
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            className={classes.textField}
                            helperText={errors.confirmPassword}
                            error={errors.confirmPassword ? true : false}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <h6 className="message">
                            Voters want to be able to understand the demographic
                            of the person whose views they're reading. So,
                            although these aren't required, they will help make
                            your point more persuasive if you provide this info.
                            No personal info will be shown to readers other than
                            your name if you wish to include it here.
                        </h6>

                        <FormControl className="form-control">
                            <InputLabel id="state-label">
                                Which state do you live in?
                            </InputLabel>
                            <Select
                                id="state"
                                name="state"
                                labelId="state-label"
                                value={this.state.state}
                                onChange={this.handleChange}
                                className="select"
                            >
                                {getSelectOptions(
                                    this.props.user.signupInfo.state
                                )}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control">
                            <InputLabel id="age-label">Your age:</InputLabel>
                            <Select
                                id="age"
                                name="age"
                                labelId="age-label"
                                value={this.state.age}
                                onChange={this.handleChange}
                                className="select"
                            >
                                <MenuItem value="15">17 and under</MenuItem>
                                <MenuItem value="20">18-29</MenuItem>
                                <MenuItem value="35">30-49</MenuItem>
                                <MenuItem value="55">50-69</MenuItem>
                                <MenuItem value="80">70+</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="form-control">
                            <InputLabel id="gender-label">
                                Your gender:
                            </InputLabel>
                            <Select
                                id="gender"
                                name="gender"
                                labelId="gender-label"
                                value={this.state.gender}
                                onChange={this.handleChange}
                                className="select"
                            >
                                {getSelectOptions(
                                    this.props.user.signupInfo.gender
                                )}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control">
                            <InputLabel id="views-label">
                                Politically you lean:
                            </InputLabel>
                            <Select
                                id="views"
                                name="views"
                                labelId="views-label"
                                value={this.state.views}
                                onChange={this.handleChange}
                                className="select"
                            >
                                {getSelectOptions(
                                    this.props.user.signupInfo.views
                                )}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control">
                            <InputLabel id="vote-label">
                                As of now you're probably going to vote:
                            </InputLabel>
                            <Select
                                id="vote"
                                name="vote"
                                labelId="vote-label"
                                value={this.state.vote}
                                onChange={this.handleChange}
                                className="select"
                            >
                                {getSelectOptions(
                                    this.props.user.signupInfo.vote
                                )}
                            </Select>
                        </FormControl>

                        <TextField
                            id="name"
                            name="name"
                            type="text"
                            label="Name"
                            className={classes.textField}
                            helperText={errors.name}
                            error={errors.name ? true : false}
                            value={this.state.name}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <h6 className="message info">
                            Note that providing your name is optional. If you
                            provide it, then it will be included along with your
                            submitted Top7
                        </h6>
                        {errors.general && (
                            <Typography
                                variant="body2"
                                className={classes.customError}
                            >
                                {errors.general}
                            </Typography>
                        )}
                        <div className="buttons signup-buttons">
                            <ReCAPTCHA
                                ref={(e) => (this.captcha = e)}
                                sitekey={CAPTCHA_KEY}
                                onChange={this.onEnterCaptcha}
                                hl="en"
                            />
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                className={cn(
                                    classes.button,
                                    "login-button button"
                                )}
                                disabled={loading}
                            >
                                {!this.state.success ? "Submit" : "Success!"}
                                {loading && (
                                    <CircularProgress
                                        size={30}
                                        className={classes.progress}
                                    />
                                )}
                            </Button>
                        </div>

                        <br />

                        <div className="login-link">
                            <small>
                                Already have an account ? Login{" "}
                                <div
                                    className="underlined"
                                    onClick={this.props.goToLogin}
                                >
                                    here
                                </div>
                            </small>
                        </div>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

signup.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    signupUser: PropTypes.func.isRequired,
    goToLogin: PropTypes.func,
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI,
});

export default withRouter(
    connect(mapStateToProps, { signupUser })(withStyles(styles)(signup))
);
